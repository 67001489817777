<template>
    <div class="action-request">
        <div @click="onClickContact" class="flex-row items-center">
            <i class="material-icons flex-wrap m-r-8">phone</i>
            <div v-html="body" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionRequest',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        body() {
            return this.$translate('ACTION_REQUEST_BODY').replace(/%s/, this.$mustParse(this.message.content).name)
        },
    },
    methods: {
        onClickContact() {
            const body = `${this.content.name}, ${this.content.phone_number}`

            this.$modal.custom({
                component: 'ModalContact',
                options: {
                    body: this.$options.filters.translate(body),
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.action-request {
    color: white;
    border-radius: 12px;
    padding: 10px 12px;
    background: linear-gradient(285deg, #aa92ff, #8a74ff 0%);

    .real-friend & {
        background: $blue-facebook;
    }

    i {
        border-radius: 8px;
        background: white;
        color: $purple-primary;
        width: 24px;
        height: 24px;
        font-size: 18px;

        @include center;
    }
}
</style>
